import { geoMercator } from 'd3-geo';
import createjs from 'createjs';
import debounce from 'lodash/debounce.js';
import eachElement from 'Utils/eachElement.js';
import utils from './utils.js';


export default {

	/* Global vars */

	// EaselJS stage for drawing on the canvas
	stage: null,

	// D3 map projection
	projection: null,

	// Whether or not the map is currently doing its reveal animation
	animating: false,

	// The width and height of the area each point takes on the map (not the point width itself)
	slotSize: 22,

	// Number of columns and rows of points that will fit on the canvas
	cols: 0,
	rows: 0,

	// Number of pixels to shunt the point grid down and right to centre it in the canvas
	colPadding: 0,
	rowPadding: 0,

	// Array of the pixel coordinates for the centres of each map point
	colPoints: [],
	rowPoints: [],


	/* Methods */

	init(callback) {
		/* Create map elements */
		this.createMap();

		/* Hide map when resizing */
		window.addEventListener('resize', () => {
			document.querySelector('#trust-map')?.classList.add('is-shy');
		});

		/* Reset and redraw the map when the window is resized */
		window.addEventListener('resize', debounce(() => {
			/* Recreate map */
			this.createMap();

			// Set size
			const dotAnimation = document.querySelector('#trust-map-foreground');
			const mapAnimation = document.querySelector('#trust-map-background');

			const dotAnimationRect = dotAnimation?.getBoundingClientRect();
			const dotAnimationCtx = dotAnimation?.getContext('2d');
			dotAnimationCtx.canvas.width = dotAnimationRect.width * 2;
			dotAnimationCtx.canvas.height = dotAnimationRect.height * 2;

			const mapAnimationRect = mapAnimation?.getBoundingClientRect();
			const mapAnimationCtx = mapAnimation?.getContext('2d');
			mapAnimationCtx.canvas.width = mapAnimationRect.width * 2;
			mapAnimationCtx.canvas.height = mapAnimationRect.height * 2;

			// Set up stage
			createjs.Ticker.removeEventListener('tick', this.dotAnimationStage);
			createjs.Ticker.removeEventListener('tick', this.mapAnimationStage);

			this.dotAnimationStage = new createjs.Stage('trust-map-foreground');
			this.mapAnimationStage = new createjs.Stage('trust-map-background');

			createjs.Ticker.framerate = 60;

			createjs.Ticker.addEventListener('tick', this.dotAnimationStage);
			createjs.Ticker.addEventListener('tick', this.mapAnimationStage);

			// Reset vars
			this.cols = 0;
			this.rows = 0;
			this.colPadding = 0;
			this.rowPadding = 0;
			this.colPoints = [];
			this.rowPoints = [];

			// Update the projection for the new map width
			const mapWidth = mapAnimationRect.width;
			const mapWidthToHeightRatio = 0.517;

			this.projection = geoMercator()
				.scale((mapWidth * 1.025) / Math.PI / 2)
				.center([8.2, 24]) // Trial and error center for this map
				.translate([mapWidth / 2, (mapWidth * mapWidthToHeightRatio) / 2]);

			// Calculate map
			this.calculateMapDots(callback);
		}, 500));


		/* Hide and pause map when it's scrolled out of view */
		for (const event of ['scroll', 'resize']) {
			window.addEventListener(event, () => {
				// Check if the map container is even partially visible
				if (utils.isInViewport(document.querySelector('#trust-map'))) {
					// Reveal map
					document.querySelector('#trust-map')?.classList.remove('is-hidden');
					// Reset animating
					if (this.dotAnimationStage) {
						createjs.Ticker.removeEventListener('tick', this.dotAnimationStage);
						createjs.Ticker.addEventListener('tick', this.dotAnimationStage);
					}
				} else {
					// Hide map
					document.querySelector('#trust-map')?.classList.add('is-hidden');
					// Pause animating
					if (this.dotAnimationStage) {
						createjs.Ticker.removeEventListener('tick', this.dotAnimationStage);
					}
				}
			});
		}
	},

	/* Create the map canvas elements */
	createMap() {
		/* Clear old map, if any */
		eachElement('#trust-map-foreground, #trust-map-background, #trust-map-resizer', element => {
			element.remove();
		});

		/* Create new elements */
		if (document.querySelector('#trust-map')) {
			document.querySelector('#trust-map').innerHTML = '<canvas id="trust-map-foreground"></canvas><canvas id="trust-map-background"></canvas><canvas id="trust-map-resizer"></canvas>';
			document.querySelector('#trust-map').classList.remove('is-shy');
		}
	},

	/* Calculate map dots for the given canvas */
	calculateMapDots(callback) {
		this.animating = true;

		// Figure out number of cols and rows in available space
		this.cols = Math.floor(this.mapAnimationStage.canvas.width / this.slotSize);
		this.rows = Math.floor(this.mapAnimationStage.canvas.height / this.slotSize);

		// Make sure the size is at least the minimum
		this.cols = Math.max(this.cols, 30);
		this.rows = Math.max(this.rows, 15);

		// Figure out the offset to make sure the grid is in the centre of stage
		this.colPadding = this.mapAnimationStage.canvas.width % this.slotSize / 2;
		this.rowPadding = this.mapAnimationStage.canvas.height % this.slotSize / 2;

		// Use a second hidden canvas to resize the map, so it doesn't blink
		const resizer = document.querySelector('#trust-map-resizer');
		const ctx = resizer.getContext('2d');

		resizer.style.width = this.cols;
		resizer.style.height = this.rows;
		ctx.canvas.width = this.cols;
		ctx.canvas.height = this.rows;

		// Make sure resizing is as rough as possible to keep it sharp
		ctx.msImageSmoothingEnabled = false;
		ctx.mozImageSmoothingEnabled = false;
		ctx.webkitImageSmoothingEnabled = false;
		ctx.imageSmoothingEnabled = false;

		// Load map image
		const image = new Image();
		image.addEventListener('load', () => {
			// Draw the map image in the given dimensions
			ctx.drawImage(image, 0, 0, this.cols, this.rows);
			const data = ctx.getImageData(0, 0, this.cols, this.rows);

			const animationPromises = [];

			// Go through each row/col position
			for (let row = 0; row < this.rows; row++) {
				for (let col = 0; col < this.cols; col++) {
					// Calculate the current i in data.data given the row and col
					const pos = (row * this.cols * 4) + (col * 4);

					// Calculate luminosity for that particular pixel
					const lum = (0.299 * data.data[pos]) + (0.587 * data.data[pos + 1]) + (0.114 * data.data[pos + 2]);

					// If it's darker than 50%, let's draw a point
					if (lum < 127) {
						const point = new createjs.Shape();
						point.y = (row * this.slotSize) + (this.slotSize / 2) + this.rowPadding;
						point.x = (col * this.slotSize) + (this.slotSize / 2) + this.colPadding;
						point.alpha = 0;
						this.mapAnimationStage.addChild(point);

						// Animate the point
						point.width = 0;
						const pointAnim = createjs.Tween.get(point)
							.wait((utils.getRandomNumber(4, 7) * col) + (utils.getRandomNumber(12, 20) * row))
							.to({ width: 10, alpha: 0.55 }, 200, createjs.Ease.getPowInOut(3))
							.to({ alpha: 0.05 }, utils.getRandomNumber(450, 600), createjs.Ease.cubicOut);

						// Redraw per frame
						pointAnim.addEventListener('change', () => {
							point.graphics.clear();
							point.graphics.beginFill('white').drawCircle(0, 0, point.width);
						});

						// Save a promise and resolve once the animation is complete
						animationPromises.push(new Promise(resolve => {
							pointAnim.addEventListener('complete', () => {
								resolve(true);
							});
						}));
					}
				}

				// Save the y coordinate of each row
				this.rowPoints.push(row * this.slotSize / 2);
			}

			// Save the x coordinate of each column
			for (let col = 0; col < this.cols; col++) {
				this.colPoints.push(col * this.slotSize / 2);
			}

			// Once all dots have finished animating
			Promise.all(animationPromises).then(() => {
				this.animating = false;

				// Stop redrawing the map
				setTimeout(() => {
					createjs.Ticker.removeEventListener('tick', this.mapAnimationStage);
				}, 1000);

				if (callback) {
					callback();
				}
			});
		});

		// Defining the image source down here will trigger the onload event block above
		image.src = '/images/trust/world-map-filled.jpg';
	},


	/* Create and animate a dot given a map coordinate */
	addLocationToMap([Long, Lat]) {
		// If we're in the middle of an animation, forget about this point
		if (this.animating) {
			return true;
		}

		// Convert map coordinates to pixel coordinates
		const location = this.projection([Long, Lat]);

		// Normalise to match the nearest dot matrix map point location
		const normX = (utils.getClosestToInArray(location[0], this.colPoints) * 2) + (this.slotSize / 2) + this.colPadding;
		const normY = (utils.getClosestToInArray(location[1], this.rowPoints) * 2) + (this.slotSize / 2) + this.rowPadding;

		// Create the shockwave ring
		const ring = new createjs.Shape();
		ring.x = normX;
		ring.y = normY;
		this.dotAnimationStage.addChild(ring);

		// Animate shockwave
		ring.width = 0;
		const ringAnim = createjs.Tween.get(ring)
			.to({ width: 80 }, 1150, createjs.Ease.getPowInOut(5));

		// Redraw per frame
		ringAnim.addEventListener('change', () => {
			ring.graphics.clear();
			ring.graphics.setStrokeStyle(((ring.width * -1) + 80) / 2).beginStroke('#F8A323').drawCircle(0, 0, ring.width);
		});

		// Destroy on completion
		ringAnim.addEventListener('complete', () => {
			ring.graphics.clear();
		});

		// Create the indicator dot
		const dot = new createjs.Shape();
		dot.x = normX;
		dot.y = normY;
		dot.shadow = new createjs.Shadow('#F8A323', 0, 0, 20);
		this.dotAnimationStage.addChild(dot);

		// Animate dot
		dot.width = 0;
		const dotAnim = createjs.Tween.get(dot).wait(300)
			.to({ width: 10 }, 500, createjs.Ease.getPowInOut(3))
			.to({ alpha: 0 }, 60000, createjs.Ease.Linear);

		// Redraw per frame
		dotAnim.addEventListener('change', () => {
			dot.graphics.clear();
			dot.graphics.beginFill('white').drawCircle(0, 0, dot.width);
		});

		// Destroy on completion
		dotAnim.addEventListener('complete', () => {
			dot.graphics.clear();
		});
	},
};
