import * as signalR from '@microsoft/signalr';

import errorHandler from 'Utils/errorHandler.js';
import map from './map.js';
import numbers from './numbers.js';
import utils from './utils.js';


export default {

	websocketHostName: 'https://trust.gearset.com',

	initialised: false,

	connection: null,

	init() {
		/* Set up socket connection for initialisation when the dom is ready */
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(this.websocketHostName + '/api/locationHub', {
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets,
			})
			.build();
	},

	/* Connect to the websocket, and hook up all event listeners */
	initialiseWebsocket() {
		if (this.initialised) {
			return true;
		}

		// If we've already connected before, give up
		if (this.connection.state === 1) {
			return true;
		}

		this.connection.start()
			.then(() => {
				this.initialised = true;
			})
			.catch(errorHandler);
	},

	initialiseNumbersSocket() {
		// Clean up any previous connections
		this.connection.off('ReceiveAppStats');
		this.connection.off('InitialiseAppStats');

		this.connection.on('ReceiveAppStats', appStats => {
			// We're updating these from the ping events directly to keep them in sync
			delete appStats.totalDeployments;
			delete appStats.totalComparisons;

			numbers.updateAppStats(appStats);
		});

		this.connection.on('InitialiseAppStats', appStats => {
			numbers.updateAppStats(appStats);
		});
	},

	initialiseMapSocket() {
		// Clean up any previous connections
		this.connection.off('ReceiveRecentCoordinates');
		this.connection.off('ReceiveNewCoordinates');

		// Receive recent pings from the trust api (sent when connection established)
		this.connection.on('ReceiveRecentCoordinates', recentActivityPing => {
			for (const activityPing of recentActivityPing) {
				const { latitude, longitude } = activityPing.coord;
				const minsSince = activityPing.minsSince;
				setTimeout(() => {
					map.addLocationToMap([longitude, latitude]);
				}, utils.getSemiRandomisedTime(minsSince));
			}
		});

		// Send pings to map when sent comparison or deployment from the trust api
		this.connection.on('ReceiveNewCoordinates', activityPing => {
			const { latitude, longitude } = activityPing.coord;

			const eventStatName = {
				Comparison: 'totalComparisons',
				Deployment: 'totalDeployments',
				NotSupported: 'totalDeployments',
			}[activityPing.event];

			numbers.incrementStat(eventStatName);
			map.addLocationToMap([longitude, latitude]);
		});
	},
};
