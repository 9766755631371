export default {
	/* Return a random float between two given float */
	getRandomNumber(min, max) {
		return (Math.random() * (max - min)) + min;
	},

	/* Return the numerically closest value to a given number in a given array */
	/* https://stackoverflow.com/a/19277804/951402 */
	getClosestToInArray(goal, array) {
		/* eslint-disable-next-line unicorn/no-array-reduce */
		return array.reduce((previous, curr) => (Math.abs(curr - goal) < Math.abs(previous - goal) ? curr : previous));
	},

	/* Return slightly shuffled number of milliseconds against a given number of minutes */
	getSemiRandomisedTime(minsSince) {
		return (minsSince * 80000 * Math.random()) + (Math.random() * 5000);
	},

	/* Check if the given DOMElement is visible on screen */
	isInViewport(element) {
		const rect = element.getBoundingClientRect();
		const ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		const hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		const w = element.clientWidth;
		const h = element.clientHeight;
		return ((rect.top < hw && rect.top + h > 0) && (rect.left < ww && rect.left + w > 0));
	},
};
