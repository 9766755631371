/* Dependencies */
import { CountUp } from 'countup.js';
import eachElement from 'Utils/eachElement.js';


export default {
	/* Global vars */

	/* Numbers loaded from socket */
	numbers: {
		totalDeployments: 0,
		totalComparisons: 0,
		totalDataBackedUp: 84331118590,
		totalMetadataComponentsDeployed: 0,
		totalDataRecordsDeployed: 0,
		totalTestsRun: 0,
		totalHoursSaved: 0,
		productivityIncrease: 0,
		totalReleases: 0,
		averageResponseTime: 134,
		averageRating: 98,
		uservoiceRequests: 356,
		prsMerged: 39975,
		successRate: 99,
	},

	observer: null,


	/* Methods */

	init() {
		/* Set up observer to check whether each counter is visible */
		this.observer = new IntersectionObserver((entries => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					entry.target.countup?.start();
				}
			}
		}), {
			root: null,
			rootMargin: '0px 0px -50px',
			threshold: 0.5,
		});

		/* For each element, set up a CountUp object and observer */
		eachElement('[data-number]', element => {
			const number = element.dataset.number;

			const options = {
				decimalPlaces: 0,
				formattingFn: value => {
					// Customise number formatting based on variable name (fragile?)
					switch (number) {
						case 'averageResponseTime':
							return new Date(1000 * value).toISOString().slice(11, 19);

						default:
							return value.toLocaleString('en');
					}
				},
			};

			/* Add countup instance to element */
			element.countup = new CountUp(element, this.numbers[number], options);

			this.observer.observe(element);
		});
	},

	/* Update all CountUp objects for the given list of new stats */
	updateAppStats(appStats) {
		for (const appStat of Object.entries(appStats)) {
			this.updateStat(appStat);
		}
	},

	incrementStat(appStatName) {
		const endValue = document.querySelector(`[data-number="${appStatName}"]`).countup?.endVal || 0;
		this.updateStat([appStatName, endValue + 1]);
	},

	/* Update the number for a given CountUp object, or do nothing if no CountUp object exists yet */
	updateStat(appStat) {
		const [statName, statValue] = appStat;

		eachElement(`[data-number="${statName}"]`, element => {
			const cu = element.countup;
			/* Set counter value to new stat */
			/* If the animation has started, update the value appropriately */
			/* If it hasn't begun yet (has not been scrolled into view), update kludgily */
			if (cu.startTime) {
				// Start the start as the previous end - no idea why it doesn't do this automatically
				cu.startVal = cu.endVal;
				cu.update(statValue);
			} else {
				cu.endVal = statValue;
			}

			/* Hack to force IntersectionObserver to refresh, in order to trigger .start() properly */
			this.observer.unobserve(element);
			this.observer.observe(element);
		});
	},
};
